import { CoordinatesFieldComponent } from './coordinates-field/coordinates-field.component'
import { ElementsFieldComponent } from './elements-field/elements-field.component'
import { AxisFieldComponent } from './axis-field/axis-field.component'

// Components
export * from './axis-field/axis-field.module'
export * from './axis-field/axis-field.component'
export * from './coordinates-field/coordinates-field.module'
export * from './coordinates-field/coordinates-field.component'
export * from './elements-field/elements-field.component'
export * from './elements-field/elements-field.module'

// Module
export { FormExtensionsModule } from './form-extensions.module'

export const formExtensions = {
  types: [
    {
      name: 'coordinates',
      component: CoordinatesFieldComponent
    },
    {
      name: 'axis',
      component: AxisFieldComponent
    },
    {
      name: 'elements',
      component: ElementsFieldComponent
    }
  ]
}
