import {
  ViewContainerRef,
  ComponentRef,
  DestroyRef,
  Component,
  inject,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core'
import { isObservable, Observable, of } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

import { SelectComponent } from '@satinel-system/form'

import { BackendService, EntityInterfaces } from '@goplanner/api-client'

import { AxisComponent } from 'src/app/pages/home/modules/vehicles/axis/axis.component'

@Component({
  selector: 'app-formly-elements',
  templateUrl: './elements-field.component.html'
})
export class ElementsFieldComponent
  extends SelectComponent
  implements OnInit, OnChanges
{
  readonly destroy = inject(DestroyRef)

  axisDialog: ComponentRef<AxisComponent> | undefined

  classVal: number | undefined

  loadingObs!: Observable<boolean>

  constructor(
    private vcr: ViewContainerRef,
    private backend: BackendService
  ) {
    super()
  }

  result: any[] = []

  ngOnInit() {
    const key: keyof EntityInterfaces | undefined = this.props['table_name']

    if (!key) return

    this.backend
      .getAll(key)
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((options) => {
        this.result = options
      })
    this.loadingObs = this.backend.loading$(key)
    // this.form.valueChanges
    //   .pipe(takeUntilDestroyed(this.destroy))
    //   .subscribe((values) => {
    //     if (
    //       this.classFilter &&
    //       this.classFilter in values &&
    //       values[this.classFilter]
    //     ) {
    //       this.classVal = values[this.classFilter]
    //     } else if (this.classFilter && this.classFilter in values) {
    //       this.classVal = undefined
    //     }
    //   })
  }

  ngOnChanges() {
    const key: keyof EntityInterfaces | undefined = this.props['table_name']

    if (!key) return

    this.backend
      .getAll(key)
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((options) => {
        this.result = options
      })
    this.loadingObs = this.backend.loading$(key)
    // this.form.valueChanges
    //   .pipe(takeUntilDestroyed(this.destroy))
    //   .subscribe((values) => {
    //     if (
    //       this.classFilter &&
    //       this.classFilter in values &&
    //       values[this.classFilter]
    //     ) {
    //       this.classVal = values[this.classFilter]
    //     } else if (this.classFilter && this.classFilter in values) {
    //       this.classVal = undefined
    //     }
    //   })
  }

  override get items(): Observable<any[]> {
    const options = this.props['options'] ?? []
    if (isObservable(options)) {
      return options
    }
    return of(options)
  }
}
