import {
  ViewContainerRef,
  ComponentRef,
  DestroyRef,
  Component,
  inject,
  OnInit
} from '@angular/core'
import { isObservable, debounceTime, Observable, of } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

import { SelectComponent } from '@satinel-system/form'

import { BackendService, EntityInterfaces } from '@goplanner/api-client'

import { AxisComponent } from 'src/app/pages/home/modules/vehicles/axis/axis.component'

@Component({
  selector: 'app-formly-axis',
  templateUrl: './axis-field.component.html'
})
export class AxisFieldComponent extends SelectComponent implements OnInit {
  readonly destroy = inject(DestroyRef)

  axisDialog: ComponentRef<AxisComponent> | undefined

  classVal: number | undefined

  loadingObs!: Observable<boolean>

  constructor(
    private vcr: ViewContainerRef,
    private backend: BackendService
  ) {
    super()
  }

  allItems: EntityInterfaces['sys_ent_veh_configuracion_ejes']['get'][] = []
  filteredItems: EntityInterfaces['sys_ent_veh_configuracion_ejes']['get'][] =
    []

  ngOnInit() {
    this.backend
      .getAll('sys_ent_veh_configuracion_ejes')
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((options) => {
        this.allItems = options
        if (this.classFilter && this.form.value[this.classFilter]) {
          this.classVal = this.form.value[this.classFilter]
          this.filteredItems = this.filterItems(options)
        }
      })
    this.loadingObs = this.backend.loading$('sys_ent_veh_configuracion_ejes')
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((values) => {
        if (
          this.classFilter &&
          this.classFilter in values &&
          values[this.classFilter]
        ) {
          this.classVal = values[this.classFilter]
          this.filteredItems = this.filterItems(this.allItems)
        } else if (this.classFilter && this.classFilter in values) {
          this.classVal = undefined
          this.filteredItems = this.filterItems(this.allItems)
        }
      })
  }

  filterItems(
    items: EntityInterfaces['sys_ent_veh_configuracion_ejes']['get'][]
  ) {
    return items.filter(
      (item) =>
        !this.classVal ||
        item.configuracion_remolque ===
          ([1, 33].includes(this.classVal) ? false : true)
    )
  }

  openAxisWindow() {
    if (this.axisDialog) return
    this.axisDialog = this.vcr.createComponent(AxisComponent)
    this.axisDialog.instance.mode = 'dialog'
    this.axisDialog.instance.selectedId =
      this.form.value[this.field.key as string]
    this.axisDialog.instance.filteredOption =
      this.classVal && [1, 33, 34].includes(this.classVal)
        ? 'isTractora'
        : 'isRemolque'
    this.axisDialog.instance.visible = true
    const select = this.axisDialog.instance.selecter.subscribe((item) => {
      this.formControl.setValue(item)
    })
    const destroy = this.axisDialog.instance.destroy
      .pipe(debounceTime(200))
      .subscribe(() => {
        destroy?.unsubscribe()
        select?.unsubscribe()
        this.axisDialog?.destroy()
        this.axisDialog = undefined
        const mask = document.querySelector(
          '.p-dialog-mask.p-component-overlay.p-component-overlay-enter.p-dialog-mask-scrollblocker'
        )
        mask?.remove()
      })
  }

  get classFilter() {
    return this.props['classFilter'] ?? undefined
  }

  override get items(): Observable<
    EntityInterfaces['sys_ent_veh_configuracion_ejes']['get'][]
  > {
    const options = this.props['options'] ?? []
    if (isObservable(options)) return options
    return of(options)
  }
}
