import { NgModule } from '@angular/core'

import { CoordinatesFieldModule } from './coordinates-field/coordinates-field.module'
import { AxisFieldModule } from './axis-field/axis-field.module'
import { ElementsFieldModule } from './elements-field/elements-field.module'

@NgModule({
  imports: [CoordinatesFieldModule, AxisFieldModule, ElementsFieldModule]
})
export class FormExtensionsModule {
  static forRoot() {
    return {
      ngModule: FormExtensionsModule,
      providers: []
    }
  }
}
