<satinel-system-select
  [inputId]="id"
  [formlyField]="field"
  [formControl]="formControl"
  [options]="result"
  [optionLabel]="['nombre']"
  optionValue="id"
  [placeholder]="placeholder"
  [showClear]="showClear"
  [lazy]="lazy"
  [help]="help"
  [label]="label"
  [floatLabel]="floatLabel"
  [showFilter]="showFilter"
  [styleClass]="styleClass"
  [appendTo]="appendTo"
  [scrollHeight]="scrollHeight"
  [itemTemplate]="itemTemplate"
  [selectedItemTemplate]="selectedItemTemplate"
  [groupTpl]="groupTemplate"
  [readOnly]="readOnly"
  [required]="required"
  [showConfig]="true"
  configIcon="fas fa-truck-container-empty"
  (lazyLoad)="lazyLoad($event)"
  (filter)="filter($event)"
  (sort)="sort($event)"
  [loading]="(loadingObs | async) || false"
/>
